.container-B9 {
  height: 1080px;
  max-width: 5760px;
  background: rgb(17, 24, 39);
}

.synopsbackLogo {
  margin-top: 8rem;
  height: 63vh;
  margin-left: 4rem;
}

.buttonLeftMenu {
  background: linear-gradient(
    -63.43494882292201deg,
    rgb(0, 186, 255) 0%,
    rgb(0, 65, 240) 100%
  );
  border-radius: 8px;
  height: 95px;
  width: 380px;
  margin-top: 1.5rem;
  margin-left: 4rem;
  font-size: 28px;
  color: white;
  border-style: hidden;
  cursor: pointer;
}

.buttonIcon {
  height: 40px;
  width: 40px;
}

.buttonBelow {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(0, 186, 255) 100%
  );
  border-radius: 28px;
  height: 67px;
  width: 297px;
  margin-left: 4rem;
  color: white;
  font-size: 24px;
  padding: 10px;
}

.synopsHeading {
  color: rgb(253, 253, 253);
  font-family: "GraphikSemibold";
  font-size: 63px;
  margin-left: 5.2rem;
  margin-top: 8rem;
  margin-bottom: 1rem;
}

/* Modal Popup */

.Leftmodal {
  position: absolute !important;
  top: 1rem;
  left: 3rem;
  transform: translate(-50%, -50%);
}

.Leftmodal .modal-header {
  height: 8.4rem;
}

.LeftmodalTableau .close {
  color: white;
}
.customPopup .modal-backdrop.show {
  opacity: 0;
}

/* End Modal Popup */

/* breadcrumbs */

.breadcrumbB9 {
  font-family: 'GraphikSemibold';
  color: white;
  font-size: 1.6rem;
}

.breadcrumbB9 .Clicked {
  position: relative;
  display: inline-block;
  /*padding: 0.5rem 1.2rem .5rem .5rem; */
  background-color: rgb(0, 186, 255);
  min-width: 242px;
}

.breadcrumbB9 .Home {
  position: relative;
  display: inline-block;
  /*padding: 0.5rem 1.2rem .5rem .5rem; */
  background-color: rgb(0, 46, 153);
  width: 180px;
 /* z-index: 1; */
  font-size: 1.6rem;
}

.breadcrumbB9 .Home::after {
  position: absolute;
  content: "";
  right: -19px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid rgb(0, 46, 153);
}

.breadcrumbB9 .Clicked::after {
  position: absolute;
  content: "";
  right: -19px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid rgb(0, 186, 255);
}

/* End breadcrumbs */

/* footer */

.footerb9 {
  position: fixed;
  bottom: 3.938rem;
  padding-left: 5rem;
}

.footerLogo {
  height: 2rem;
  margin-left: 1.25rem;
  margin-top: 0.563rem;
}

/* end footer */

/* Language Holder */

.hmcc-nav .new-maintab-span {
  display: flex;
  align-items: center;
  padding: 0 0.9rem;
  margin: 0.6rem 0;
}


.profileCntnrDiv-b9 {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 520px;
  height: 100vh;
  z-index: 11;
  padding: 2rem 1.5rem 1.5rem;
}

.profileCntnrDiv-b9 h6,
.profileCntnrDiv-b9 p {
  font-size: 1.5rem;
  word-break: break-all;
}

.profileCntnrDiv-b9 .ProfileName {
  font-family: "GraphikRegular";
}

.profileLangaugeHldr-b9 {
  max-height: 61vh;
}

.profileCntnrDiv-b9 p.small {
  font-size: 0.65rem;
}

.profileCntnrDiv-b9 .profileIcon {
  width: 1rem;
  vertical-align: text-top;
}

.profileCloseBtnAncr {
  cursor: pointer;
}

.profileCloseBtn-b9 {
  cursor: pointer;
  right: 0.9rem;
  top: 0.5rem;
  position: absolute;
}

.profileCntnrDiv-b9 .profile-img {
  height: 5.625rem;
  width: 5.625rem;
  background: rgb(36, 50, 97);
}

.profileCntnrDiv-b9 {
  background: rgb(2, 23, 77);
  color: #fff;
}

.profileLangaugeHldr-b9 input[type="radio"] {
  position: relative;
  top: 2px;
}

.switchContainerTheme-b9 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(-10%, -50%, 0);
}

.switchContainerTheme-b9.disable {
  pointer-events: none;
  opacity: 0.7;
}

input[type="checkbox"].switchThemeB9 {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switchThemeB9 + div {
  vertical-align: middle;
  width: 2.7rem;
  height: 2rem;
  border-radius: 999px;
  background-color: #fff;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switchThemeB9 + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switchThemeB9 + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switchThemeB9 + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switchThemeB9:checked + div {
  width: 4rem;
  background-position: 0 0;
  background-color: rgb(187, 48, 48);
}

input[type="checkbox"].switchThemeB9 + div {
  width: 2.7rem;
  height: 2rem;
  transform: scale(0.8);
  transform-origin: center;
}

input[type="checkbox"].switchThemeB9:checked + div {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

input[type="checkbox"].switchThemeB9 + div > div {
  float: right;
  width: 1.8rem;
  height: 1.7rem;
  border-radius: inherit;
  background: rgb(29, 199, 179);
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1.5px;
  margin-right: 25px;
  text-align: center;
}

input[type="checkbox"].switchThemeB9:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: rgb(29, 199, 179);
}

input[type="checkbox"].bigswitch.switchThemeB9 + div > div {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 1px;
}

input[type="checkbox"].switchThemeB9:checked + div > div {
  -webkit-transform: translate3d(1.2rem, 0, 0);
  transform: translate3d(1.2rem, 0, 0);
}

input[type="checkbox"].switchThemeB9 + div > div img {
  height: 0.6rem;
}

/* Language Holder End */

#myDropdownB9 {
  height: 45px;
  display: flex;
  width: 27.2rem !important;
  align-items: center;
  padding-left: 1rem;
}

#myDropdownB9:hover {
  background-color: rgb(53, 61, 90);
}

.b9-languagedropdown {
  min-width: 240px;
  margin-left: 1rem;
  margin-top: 1rem;
}

.b9-languagedropdown-options  #myDropdownB9:hover{
  color: rgb(255, 255, 255);
  background-color: rgb(23, 47, 112);
}

.b9-languagedropdown-options {
  width: 27rem;
  background: #02174e;
  border-radius: 0 0 8px 8px;
  border: 1px solid #018eff;
  margin: 0 0 0 1rem;
  position: absolute;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0 .125rem .5rem 0rem rgb(0 0 0/15%);
  overflow-x: hidden;
  max-height: 50vh;
  font-size: 1.2rem;
}


.b9-languageSearch {
  border: 1px solid rgb(1, 142, 255) !important;
  margin: 15px 13px 15px 13px;
  padding: 6px 6px 6px 40px;
  background: url(Images/search-grey-icon.png) 10px 5px scroll no-repeat;
  background-size: 16px;
}
.b9-languageSearch#myInput {
  border: 1px solid rgb(1, 142, 255) !important;
  color: #fff !important;
  font-size: 1.2rem;
}
.hmcc-drop-btn-b9 {
  padding: 2px 15px;
  width: auto;
  text-align: left;
  margin: 0px 5px 0.25rem;
  outline: none;
  line-height: 25px;
  font-size: 0.75rem;
  font-family: "GraphikMedium", Arial, sans-serif;
}

.hmcc-drop-btn-b9.map-filter img {
  position: absolute;
  right: 1.5rem;
  top: 1.7rem;
}

.hmcc-drop-btn-b9.map-filter {
  min-width: 27rem !important;
  margin-left: 1rem;
  margin-top: 1rem;
}

.map-filter-appliedFilters-b9 {
  background: rgb(2, 22, 78) !important;
  border: 1px solid rgb(160, 172, 183);
  border-radius: 4px;
}

/* changes */

.text-blue {
  color: blue !important;
}
.announcement-modalB9 {
  width: 50vw !important;
  top: 17vh;
  right: 6rem;
}
.announcement-modalB9 .modal-content {
  background: rgb(0, 24, 95);
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.24);
  height: 472px;
  width: 585px;
}
.bellIconB9 {
  min-height: 38px;
  width: 37px;
}
.bellIconHldrB9 {
  cursor: pointer;
}
.bellIconHldrB9 img {
  height: 0.7rem;
  margin-top: 0.2rem;
  margin-left: 1.5rem;
}
.verticalLine{
  border-left: 2px solid whitesmoke;
  height: 40px;
  margin-left: 21px;
  margin-top:6px;
}
.announcement-modalB9 ::-webkit-scrollbar {
  width: 6px !important;
}
.announcement-modalB9 .announcementDescCntnr {
  width: auto;
}
/* .HMFilter-btn-holderb9 img {
  height: 2.1rem;
  margin: 0.2rem;
  position: relative;
  top: -5px;
  left: 5px;
} */

.transparent-bg-topb9 {
  background-color: blue;
  height: auto;
}

.hmcc-button-heightb9 {
  min-height: 2.2rem;
}

.mapb9width {
  display: flex;
  justify-content: center;
  overflow: visible !important;
}

/* .b9-bg-top {
  background-color: rgba(119, 160, 255, 0.2);
  padding: 0 0.936rem 0.688rem 0.936rem;
  height: 4.3em;
  position: relative;
  max-width: 73em !important;
} */

.iframe-modal-body-B9 {
  height: 70vh;
}

.Leftmodal .close {
  color: white;
}

.modal-iframe-B9 {
  min-height: 82vh !important;
  width: 125% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(0.8) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(0.8) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(0.8) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(0.8) !important;
  -webkit-transform-origin: 0 0 !important;
  margin-top: 2rem;
}
@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 5700px) and (max-width: 5760px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    top: 0vh !important;

    border-radius: 0.5em;
  }
  .hmcc-dd-section .filter-dropbtn-parent .map-filter {
    /* changes */
    /* min-width: 11.3rem !important; */
    min-width: 14.8rem !important;
    position: relative;
    left: 34px !important;
  }
  /* .modal-90w {
    max-width: 31.5% !important;
  } */
}

.filterfooterb9 {
  margin-top: auto;
  margin-left: -28px !important;
  flex-wrap: nowrap !important;
  margin-right: 28px !important;
  margin-bottom: 1.68em;
}
.mapb9-dropdown {
  color: #fff;
  background-color: #00185f;
  width: 62%;
  border: none;
  margin-left: 15%;
}
.img-responsive {
  width: inherit;
}

.dd-list-item {
  background-color: rgb(8, 39, 133);
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  margin-left: 20%;
  width: 60%;
}

.business-height-b9 {
  height: 20vh;
  zoom: 0.8;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: -80px;
}
.business_modal-holder-b9 {
  margin: 0 !important;
  left: 65.5%;
  top: 0%;
  backdrop-filter: brightness(0.5);
}
.business_modal-holder-b9 .modal-content{
  left:-131%
}
.data-container2-b9 {
  padding: 15px 20px;
  background: rgb(0, 102, 204) !important;
}
.tab4-backgroundb9 .modal-backdrop.show {
  opacity: 0;
}
.b9-bo-modal {
  /* height: 63vh; */
  width: 100%;
  border-radius: 0px !important;
}
@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 5700px) and (max-width: 5760px) {
  .business_modal-holder-b9 .modal-lg {
    max-width: 25vw;
  }
  .modal-backdrop.show {
    opacity: 0;
    z-index: -1;
  }
  .dashboard-modal-header {
    background-color: #0058ff;
  }
  .announcements-height {
    height: 5vh;
    margin-top: 12px;
  }
  .data-sections2 {
    padding: 0px;
  }
  .operations-height {
    height: 30.875rem;
    zoom: 0.7;
  }
  .live-notifications-height {
    height: 17.6vh;
    zoom: 0.8;
  }
  .dropdown-content {
    max-height: 20vh !important;
  }
  .world-map {
    padding-top: 4vh;
  }
}
.positive-z-index {
  z-index: 1;
}
.negative-z-index {
  z-index: -1;
  display: none !important;
}

/* b9  css by keerthi */
.powered-heightb9 {
  height: 10vh;
  overflow: hidden;
  zoom: 0.8;
  margin-top: 15px;
}
.modal-90w-mapb9 {
  width: 90% !important;
  height: 60% !important;
}
.mapb9table {
  color: white;
  background: rgb(8, 39, 133);
  border-radius: 2px;
  border: 1.7px solid rgb(255, 120, 73);
  height: 459px;
  width: 850px;
  max-width: 100%;
  margin-bottom: 1rem;
}
.mapb9table .table thead {
  border-bottom: hidden;
}
.mapb9table .table tbody tr {
  border-bottom: hidden;
}
.tableWidthB9 .react-bootstrap-table .table thead tr{
  font-size: 19px !important;
}
.tableWidthB9 .react-bootstrap-table .table tbody tr{
  font-size: 18px !important;
  height: 6.5vh;
}
.image-panel {
  height: 33vh;
  width: 15em;
  margin: auto;
  margin-top: 5vh;
}
.dropdown-width .dropdown-content {
  width: 12rem !important;
}
.modal-90w-mapb9 .modal-content {
  background: rgb(0, 24, 95);
  height: 611px;
  width: 80em;
  opacity: 0.94;
}
.modal-90w-mapb9 .modal-content .modal-header {
  border-bottom: none;
}
.pagination-b9 .react-bootstrap-table-pagination {
  float: right;
  margin-right: 7em;
}
.pagination-b9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item
  .page-link {
  background-color: rgb(0, 99, 179) !important;
  color: white;
}
.pagination-alertB9 .react-bootstrap-table-pagination {
  /* float: right;
  margin-left: 47.3rem; */
  width:100%
}
.pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item
  .page-link {
  background-color: transparent;
  border-radius: 3px;
  color: white;
  border: none;
  box-shadow: none !important;
}
.pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-child(1),
  .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-child(2),
  .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-last-child(2){
    background-color:rgb(12, 52, 141) !important ;
    border: none !important;
  }
  .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alertB9
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-last-child(1){
    /* border-radius: 3px;
    border: 1px solid rgb(220, 226, 231); */
    background-color:rgb(12, 52, 141) !important ;
    border: none !important;
  }
  .pagination-alertB9 .react-bootstrap-table table td , .pagination-alertB9 .react-bootstrap-table table th {
    white-space: nowrap;
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid rgb(0, 24, 95);
    cursor: pointer;
  }
  .pagination-alertB9 .react-bootstrap-table table td {
    padding: 0.5rem 0.3rem 0.5rem 0.9rem !important;
    vertical-align: inherit;
    color: #fff;
  }
  .pagination-alertB9 .react-bootstrap-table .table{
    background: rgba(79, 95, 132, 0.42) !important;
    border-radius: 0px ;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    width: 56rem;
    }
  .pagination-alertB9 .react-bootstrap-table {
      font-family: GraphikMedium !important;
      min-height: 46vh;
  }
  .pagination-alertB9 .page-item{
    margin-left: 9px !important;
  }

.fa-width {
  font-size: 1.5em !important;
}

.tab4-backgroundb9 {
  width: 98% !important;
}
.container-fluidb9 {
  width: 98%;
  margin-right: auto;
  margin-left: auto;
}
.main-hmcc-footerb9 {
  position: fixed;
  bottom: 2vh;
  margin-left: 18.6em;
}
.map-popupb9 {
  position: absolute !important;
  /* top: 24%;
  left: 37%;
  max-width: 27% !important; */
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.mapb9Position {
  /* width: 24vw;
  left: 37%;
  top: 24%;
  height: 60%; */

  width: 30vw !important;
    left: 33% !important;
    top: 0% !important;
    /* height: 60%; */
    padding-left: 222px !important;
    padding-top: 16rem !important;
}
.text-blue {
  color: blue !important;
}
.HMFilter-btn-holderb9 img {
  height: 2.8rem;
  margin: 0.2rem;
  position: relative;
  top: -5px;
  left: 40px;
  /* Tejinder */
}

.transparent-bg-topb9 {
  background-color: blue;
  height: auto;
}

.hmcc-button-heightb9 {
  /*changes*/
  height: 2.4rem;
}


.mapb9width {
  display: flex;
  justify-content: center;
  overflow: visible !important;
}
.b9-bg-top {
  /* background-color: rgba(119, 160, 255, 0.2); */
  background-color: rgba(2, 20, 70, 0.9);
  padding: 0 0.936rem 0.688rem 0.936rem;
  height: 5rem;
  position: relative;
  max-width: 86.25rem !important;
  border-radius: 0 0 1rem 1rem;
  overflow: hidden !important;
}

.b9-bg-top .hmcc-text .clientServed {
  font-size: 20px !important;

}

.b9-bg-top .hmcc-d-flex .hmcc-flex-child h6, .b9-bg-top .hmcc-d-flex .hmcc-flex-child h5 {
  font-size: 20px !important;
}
.b9-bg-top .hmcc-d-flex .hmcc-flex-child .info-text {
  font-family: 'GraphikSemibold' !important;
}
.b9-bg-top .hmcc-d-flex .hmcc-flex-child {
  margin: 0.48rem !important;
}
.transparent-bg-bottom-b9 .f-14 {
  font-size: 20px;
    position: absolute;
    right: 0;
    top: 3px;
}



.map-filter-height {
  max-height: 10rem;
}
@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 5700px) and (max-width: 5760px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    /* top: 0vh !important; */
    top: 4vh !important;
    border-radius: 0.5em;
    height: auto;
    min-height: max-content;
  }
  .hmcc-dd-options {
    left: 1.6em;
    border-radius: 1rem;
    width: 89%;
  }
  .hmcc-client-selected {
    min-width: 90%;
    margin-top: 11px;
  }
  .selected-client-text {
    font-family: "GraphikMedium";
    font-size: 20px;
    position: relative;
    top: 0.8rem;
  }
  /* .hmcc-dd-section .filter-dropbtn-parent .map-filter {
   
    min-width: 12.8rem !important;
    position: relative;
    left: 19px !important;
  } */
  .hmcc-dd-section .hmcc-drop-btn.map-filter {
    border-radius: 1rem;
    /*changes*/
    margin: 0.8rem 0.8rem;
  }
  /*changesss*/
  .hmcc-drop-btn.map-filter img {
    position: absolute;
    right: 2rem;
    top: 0.8rem;
  }
  .hmcc-dd-section .filter-dropbtn-parent {
    max-height: 50vh;
  }
  .ClientListInner {
    height: 20vh;
  }
  .selectClientHolderDivB9 {
    /* top: 5.5vh;
    left: 0vh; */
    /*changes*/
    top: 6vh;
    left: 5vh;
    width: 14rem;
    min-height: 0px;
    max-height: 0px;
  }
  .selectClientHolderDivB9{
    border-radius: 1rem;
    /* min-height: 26rem; */
    max-height: 80vh;
    width: 14.8rem;
    position: absolute;
    left: 2.8rem;
    /* top:9vh; */
    z-index: 10000;
    text-align: left;
    padding: 1rem 0px;
  }

  .selectClientHolderDivB9 {
    background: rgba(2, 20, 70, 0.9);
    border: 1px solid rgb(27, 94, 246);
    color: #fff;
  }
  .selectClientHolderDivB9 a {
    color: rgb(145, 158, 171);
  }
  .selectClientHolderDivB9 a:hover {
    background: rgba(105, 151, 255, 0.2);
    color: #fff;
  }

  .selectClientHolderDivB9 a{
    cursor: pointer;
  }
  .selectClientHolderDivB9 h5{ 
    padding-left: 1.5rem;
    font-size: .7rem;
    margin-bottom: 0rem;
    font-family: 'GraphikRegular';
  }
  .selectClientHolderDivB9 img{
    height: 2rem;
    }
    .selectClientHolderDivB9 .media-body{
    font-size: 1rem;
    padding-top: .4rem;
    }
  .hmcc-dd-options .dropdown-content {
    width: 12rem;
  }
  .mb-2,
  .my-2 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .react-confirm-alert-overlay {
    z-index: 1051;
  }
  .app-frame-container {
    height: 80vh;
  }
  .app-store-iframe {
    min-height: 118vh;
  }
  .modal-footer {
    border-top: 0;
  }
  .react-confirm-alert {
    position: fixed;
    left: 12%;
    top: 30%;
  }
  .container {
    max-width: 1400px;
  }
   .subtab-height{
    height: 96vh;
    overflow-y: auto;
  }
  .iframe-modal-body .db-container {
    max-height: 107vh;
    height: 107vh;
  }
  .iframe-modal-body{
    max-height: 83vh;
    height: auto;
  }
  .db-container {
    max-height: 120vh;
  }
  .subtab-height1{
    overflow-y: auto;
  }
  .ioj-filter-buttons{
    top: 1rem;
  }
  .info-text{
    color: rgb(126, 195, 68) !important;
  }
  .power-bi-frame {
  height: 100vh !important;
  }
  .data-visualisation-container {
    min-height: 76vh;
    margin-top: 2vh;
  }
  .maximizedAppStoreB9 .data-visualisation-container {
    min-height: 92vh;
  }		
  .data-visualisation-container .title-section{
    zoom:100%
  }
  .data-visualisation-container .graph-view-section {
    margin-top: 0;
    zoom: 100%;
  }
  .data-visualisation-container #wheel-chart-box{
  margin-top: -0;
  }
  .modal-custom-BOI-container .modal-dialog{
    position: relative;
    left: -33%;
    top: 15vh;
  }
  .Leftmodal {
  left: 1.25rem;
}
.anchor-b9 img{
  height: 4rem !important;
}
.LeftmodalTableau {
  position: absolute !important;
  top: 1rem;
  left: 4.8rem;
  transform: translate(-50%, -50%);
}
.b9-bg-top {
  top: 2.25rem;
}

.filtPane,
.filtPaneShow {
  position: absolute;
  width: 15px;
  height: 40px;
  top: 25%;
  border: 1px solid #bbb5b5;
  border-radius: 10px 0 0 10px;
  background-color: #bbb5b5;
  text-align: center;
  cursor: pointer;
  z-index: 12;
  opacity: 0.07;
  padding-top: 5px;
} 
.b9-subtab-maximized .filtPane{
  top:50%
}
.b9-subtab-maximized .filtPaneShow{
  top:50%
}
.b9-subtab .filtPane,
.filtPaneShow {
  opacity: 1;
}
.b9-subtab .filterPanel{
  top:4.5%
}
.b9-subtab .filtContainer{
  height: 67.4vh;
}
.b9-subtab .filtPanMax .filtContainer{
  height: 84vh;
}
.b9-subtab .filtPanMax1 .filtContainer{
  height: 84vh;
}
/* B9 button filter */
.iframe-modal-body .filtPane, .filtPaneShow{
  top:50%
}
.iframe-modal-bodyb9 .filtPane, .filtPaneShow{
  top:50%
}
.iframe-modal-body .filtPane,
.filtPaneShow {
  opacity: 1;
}
.iframe-modal-body .filterPanel{
  top:2.5%
}
.iframe-modal-body .filtContainer{
  height: 75vh;
}
.iframe-modal-bodyb9 .filtContainer{
  height: 84vh;
}
.filtPane {
  right: 0;
  color: white;
}

.filtPaneShow {
  right: 290px;
  color: white;
}

.filterPanelHide {
  visibility: hidden;
  position: absolute;
  display: none;
}

.filterPanel {
  position: absolute;
  width: 290px;
  z-index: 12;
  top: 21%;
  right: 0;
  background-color: #f5f5f5;
  text-align: center;
  color: black;
  font-weight: 500;
}

.filtContainer {
  background-color: #fff;
  text-align: left;
  font-size: small;
  height: 43vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: system-ui !important;
  font-weight: 500 !important;
  color: black !important;
  font-size: 13px !important;
}
.b9-subtab .filtPane{
  top:25%
}
.b9-subtab .filtPaneShow{
  top:25%
}
.withoutButton.filterPanel{
  top: 4.5% !important;
}
.withoutButton.filterPanel .filtContainer{
  height: 67.4vh;
}
.withoutButton.filtPanMax1.filterPanel{
  top: 2% !important;
}
.withoutButton.filtPanMax1 .filtContainer{
height: 85vh;
}
.filtPanMax.filterPanel{
  top:2.7% !important;
}
.filtPanMax.filterPanel .filtContainer{
  height: 84.3vh;
}
/* .modal-content .filterPanel{
  top:2%
} */
.b9ddc5maxwidth{
  max-width: 31vw;
}
}
.filterfooterb9 {
  margin-top: auto;
  margin-left: -28px !important;
  flex-wrap: nowrap !important;
  margin-right: 28px !important;
  margin-bottom: 1.68em;
}
.main-hmcc-footer-b9 {
  position: fixed;
  bottom: 3.5vh;
  margin-left: 6.5rem;
  width: 86.25rem;
}

.filter-dropdownb9 {
  max-width: 73em !important;
  height: 30vh !important;
  justify-content: start;
}

.hmcc-new-drop-btn .applyBtnb9 {
  height: 2.25rem;
  border-radius: 1.25rem;
  border: none;
  line-height: 1.5625rem;
  font-size: 0.875rem;
  padding: 0rem;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
.hmcc-new-drop-btn .applyBtnb9:hover {
  border: none;
}
.hmcc-new-drop-btn .clearBtnb9 {
  height: 2.25rem;
  border-radius: 1.25rem;
  background: transparent;
  line-height: 1.5625rem;
  font-size: 0.875rem;
  padding: 0rem;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
.tabFontB9 #uncontrolled-tab-example-tab-Alert.active{
  /* border-bottom: 4px solid rgb(12, 227, 187) !important; */
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
  border-bottom-color:  rgb(12, 227, 187) !important ;
  display: inline-block;
  margin: 0 0 -2px 0;
  padding: 30px 3px;
}
/* .tabFontB9 #uncontrolled-tab-example-tab-Alert{
  width: 5.6rem;
  font-family: GraphikMedium !important;
} */
.tabFontB9 #uncontrolled-tab-example-tab-Announcement.active{
  border-bottom: 4px solid rgb(12, 227, 187) !important;
}
.tabFontB9 #uncontrolled-tab-example-tab-Announcement{
  width: 9.3rem;
}
.transparent-bg-bottom-b9 {
  background-size: 100%;
  height: 80px;
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem 0 0.5rem 0;
  max-width: 100% !important;
  /* transform: scale(.8); */
  transform-origin: bottom center;
  background: rgb(2, 20, 70);
}
.container1 {
  width: 86.25rem;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: 9.5rem;
}
.app-store-b9 {
  max-width: 100% !important;
}
.app-store-b9 .modal-content {
  background: #00166b;
  height: 90vh;
}
.app-store-b9 .modal-header {
  height: 119px;
  border-bottom: 0;
}

/* b9  css by keerthi end */

/* B9 Map css */
.worldMapSvg-b9 {
  top: 3rem;
  transform-origin: top;
  width: 100%;
  position: relative;
  height: 417px;
  z-index: 0;
  float: left;
  left: 11%;
}

.reactsimplemaps-b9 {
  transform: scaleX(1.21);
}

/* B9 Map css end */

.col-left {
  flex: 0 0 33%;
  max-width: 33%;
}

.col-middle {
  flex: 0 0 32%;
  max-width: 32%;
}
.col-right {
  flex: 0 0 35%;
  max-width: 35%;
  margin-left: 0%;
}
.width-90-b9 {
  max-width: 25vw !important;
}

.Leftmodal .modal-content {
  height: 93vh;
  width: 107%;
  overflow-y: hidden;
}
.B9_IntOps_buttons {
  min-height: 64vh;
  margin-top:2.4rem;
  margin-left: 1rem;
  margin-bottom: -7rem;
}
.modal-width {
  width: 32.6vw;
  backdrop-filter: brightness(0.5);
}
.b9-3rd-section {
  background: #111827;
  margin-top: 0.5rem;
  max-width: 36.5vw;
}
.data-container2-b9 .data-sections2 {
  background: rgb(0, 102, 204) !important;
}
.dash-title-b9 {
  color: rgb(255, 255, 255);
  font-family: "GraphikMedium";
  font-size: 24px;
  font-weight: 500;
  height: 29px;
  letter-spacing: -0.7px;
  margin-left: -0.3rem;
}
.anchor-b9 {
  position: absolute;
    top: 28px;
    left: 74%;
    text-align: center;
    cursor: pointer;
}
.footerSettings {
  height: 50px;
  width: 50px;
}
.dropdown-content.show.dropdown-content-B9-Asset {
  min-width: 145px !important;
  width: auto !important;
}
.dropdown-content.show.dropdown-content-B9 {
  min-width: 180px !important;
  width: auto !important;
}
.hmcc-drop-btn.hmcc-button-heightb9 {
  padding: 5px 15px !important;
}
.rectMap {
  fill: #000000;
  stroke: #263041;
  box-shadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.5)";
  border: "1px solid rgb(255, 168, 0)";
  pointer-events: all;

  /* pointer-events: all; */
}
rect.rectMap:hover {
  fill: rgb(0, 0, 0);
}
.logoutIconB9{
  border-radius: 5rem;
  padding: 5px 17px 10px 20px;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
  font-size: 1.5rem;
  font-family:  'GraphikMedium';
  color: rgb(255, 255, 255);
  position: absolute;
  right: 15px;
  bottom: 52px;
}
.confidentialB9Modal .modal-content{
  border-radius: 0.9rem;
  width: 617px;
  background: rgb(0, 24, 95);
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.24);
  margin-top: 16rem;
  right: 7rem;
}
.maximizedAppStoreB9 {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 94vh;
  width: 107%;
  border-radius: 0;
}

.maximizedIOJDashboardB9 {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 94vh;
  width: 107%;
  border-radius: 0 !important;
  margin: 0 !important;
}

.confidentialB9Popup{
    color: rgb(255, 255, 255);
    font-family: "GraphikRegular";
    font-size: 16px;
    font-weight: 500;
    height: 312px;
    letter-spacing: 0px;
    line-height: 24px;
    width: 579px;
}

.confidentialB9Modal .modal-content .modal-body {
  padding: 1.5rem;
}

.bell-count-b9{
  background: rgb(213, 0, 47);
  top: -22px;
  height: 28px;
  width: 28px;
  border-radius: 0.8rem;
  letter-spacing: -0.34px;
  text-align: center;
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  left: -0.4vw;
  color: white;
  border-style: hidden;
}
.announcementCloseBtnB9{
    width: 1rem;
    cursor: pointer;
    right: -0.1rem;
    /* top: 0.5rem; */
    position: absolute;
    color: #f9fafb;
}
.announcementCntnrDivB9 {
  position: fixed;
  left: 134rem;
  bottom: 469px;
  width: 24%;
  /* max-height: 66vh; */
  z-index: 1060;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  background: rgb(0, 24, 95);
  box-shadow: 0px 0px 12px 0px rgb(153 153 153 / 24%);
  width: 84.5rem;
  border-radius: 0.5rem;
  min-height: 61vh;
}
.announcementCntnrDivB9 .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffffd6 !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 1rem !important;
}

.announcementCntnrDivB9 .nav-tabs {
  border-bottom: 4px solid #dee2e6;
  /* width: 43%; */
}


.announcementCntnrDivB9 .nav-link {
  padding: 1rem 0rem 0rem 0.7rem !important;
}
.announcementCntnrDivB9.arrow-top {
  margin-top: 40px;
  top: 9rem;
}
.tableWidthB9 .react-bootstrap-table .table {
  background: rgba(79, 95, 132, 0.42);
  border-radius: 0px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  width: 81.6rem !important;
  /* height: 44vh; */
}

.announcementCntnrDivB9.arrow-top:after {
  /* content: " "; */
  position: absolute;
  right: 272px;
  bottom: -14px;
    /* top: -15px; */
  border: 15px solid transparent;
  border-top: 15px solid #00185f;
  border-bottom: none;
}
.announcement-content{
  /* max-height: 241px; */
  overflow-y: auto;
  overflow-x: hidden;
}
.announcementCntnrDivB9 .tab-content {
  padding-left: 0;
  padding-right: 0;
}
.announcementCntnrPopupDivB9 {
  max-height: 34vh;
  overflow-y: auto ;
  overflow-x: hidden;
}
.subtab-height .power-subtab {
  min-height:96vh !important;
}
.power-subtab iframe{
  height: 154% !important;
}
.power-button-modal iframe{
  height:155% !important;
}
.maximized-b9-popup{
  top: 1rem !important;
  margin: 0 !important;
  width: 32.5vw !important;
  max-width: none !important;
  background: #0058ff;
  left: 1rem !important;
}
.maximizeIconIOJPopupB9 {
  position: absolute;
  right: 50px;
  top: 21px;
}
.iframe-modal-bodyb9{
height: 92vh;
}
.modal-iframe-maximized-b9 {
  min-height: 111vh !important;
  width: 125% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(0.8) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(0.8) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(0.8) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(0.8) !important;
  -webkit-transform-origin: 0 0 !important;
}
.subtab-height-maxmizeIOJDashboard-b9 {
  padding: 0 15px;
  height: calc(100vh - 215px);
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 0.75;
  min-height: 116vh;
  top: -8rem;
}
.normal-b9-subtab{
  position: relative;
  top: -67rem;
  max-height: 93vh;
  overflow-y: auto;
}
.loading-b9 {
  display: block;
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: 1000;
}
.b9-sisense-max-btn{
  height: 120vh !important;
}
.height-max-powerbi-subtabB9{
  min-height: 116vh !important;
}
.increased-power-appb9{
  min-height: 137vh !important;
}
.normal-power-appb9{
  min-height: 118vh !important;
}
.filter-placeholder-b9{
  font-size: 1.25rem;
}
.b9-number-font{
  font-size: 22px !important;
}
.b9-filter-label label{
  font-size: 1rem;
}
.drop-label-b9{
font-size: 1rem;
line-height: 15px !important;
}
.remove-top-margin-b9{
  margin-top: 0;
}

.alertlabel .tabFontB9{
  border-bottom-width: 0.5px !important;
  border-bottom-style: solid !important;
  border-bottom-color:  rgb(119, 135, 218) !important;
}
.tabFontB9 .nav-item , .tabFontB9 .nav-link.active{
  color: #ffffffd6 !important;
  font-size: 16px !important;
}
.dismissClosebtn-b9{
  width: 10px;
  height: 10px;
  cursor: pointer;
  /* top: 0.5rem; */
  color: #f9fafb;
  position: absolute;
  left:97%;
}
.closeButtonSize .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem -2rem !important;
}
