iframe {
  height: 154%;
  width: 154% !important;
  -moz-transform: scale(0.65);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.65);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.65);
  -webkit-transform-origin: 0 0;
}

.multiselectDropdown {
  background: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
}

/* .css-1g8ily9-Control{
  display:flex;
  margin-top: inherit;
  margin-bottom: -6px;
}
.css-b8ldur-Input{
  display: none;
}
.css-1okebmr-indicatorSeparator{
  display: none;
} */
.drop-down-react-select {
  padding-left: 6px !important;
  padding-right: 0px !important;
  padding-top: 7px !important;
}

/* .css-tlfecz-indicatorContainer{
  padding-top: 0px !important;
} */
.modal-90w {
  width: 90% !important;
  height: 60% !important;
  max-width: none !important;
}

.dashboard-modal-header {
  border-bottom: none !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.business-height {
  height: 71vh;
  zoom: 0.68;
  overflow: hidden;
}

.powered-height {
  height: 17vh;
  overflow: hidden;
  zoom: 0.68;
}

.operations-height {
  height: 29.875rem;
  zoom: 0.5;
  overflow: auto;
}

.clientNameAdded .operations-height {
  height: 29.875rem;
}

.live-notifications-height {
  height: calc(50vh - 130px);
  zoom: 0.7;
  overflow: hidden;
}

.clientNameAdded .live-notifications-height {
  height: calc(43vh - 130px);
}

.announcements-height {
  height: calc(30vh - 125px);
  zoom: 0.7;
  overflow: hidden;
  width: calc(100% - 4px);
  margin: 2px;
  border-radius: 9px;
}

.open-tickets-height {
  height: calc(50vh - 125px);
  overflow: hidden;
}

.iframe-modal-body {
  height: 86vh;
}

.modal-iframe {
  min-height: 100vh !important;
  width: 125% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(0.8) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(0.8) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(0.8) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(0.8) !important;
  -webkit-transform-origin: 0 0 !important;
}

.modal-iframe-maximized {
  min-height: 115vh !important;
  width: 125% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(0.8) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(0.8) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(0.8) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(0.8) !important;
  -webkit-transform-origin: 0 0 !important;
}

.subtab-iframe {
  min-height: 105vh !important;
  width: 100% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(1) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(1) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(1) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(1) !important;
  -webkit-transform-origin: 0 0 !important;
}

.subtab-iframe-maximized {
  min-height: 115vh !important;
  width: 100% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(1) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(1) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(1) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(1) !important;
  -webkit-transform-origin: 0 0 !important;
}

.subtab-height {
  padding: 0 15px;
  height: calc(100vh - 215px);
  overflow-y: scroll;
  overflow-x: hidden;
  zoom: 0.75;
}

.subtab-height-maxmizeIOJDashboard {
  padding: 0 15px;
  height: calc(100vh - 215px);
  overflow-y: scroll;
  overflow-x: hidden;
  zoom: 0.75;
  min-height: 106vh;
}

.subtab-height1 {
  padding: 0 15px;
  height: calc(100vh - 21px);
  overflow-y: scroll;
  overflow-x: hidden;
  zoom: 0.75;
}

.subtab-height1-maxmizeIOJDashboard {
  padding: 0 15px;
  height: calc(100vh - 21px);
  overflow-y: scroll;
  overflow-x: hidden;
  zoom: 0.75;
  min-height: 116vh;
}

.subtab-height2 {
  padding: 0 15px;
  height: calc(100vh - 61px);
  overflow-y: scroll;
  overflow-x: hidden;
  zoom: 0.75;
}

.change-width {
  height: calc(100vh - 351px) !important;
}

.operation-main-container {
  height: auto !important;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.operations-height iframe {
  height: 154.4%;
  width: 154.4% !important;
  -moz-transform: scale(0.65);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.65);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.65);
  -webkit-transform-origin: 0 0;
}

.intops-tab-button {
  padding: 0px 5px !important;
}

.tab-height-responsive {
  font-size: 0.95vw !important;
  border-bottom-width: 0px !important;
}

.tab4-background {
  width: 100% !important;
}

.top-menu-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-content {
  position: initial !important;
  max-height: 33vh !important;
  width: 19vw !important;
}

.map-filter {
  min-width: 18vw !important;
  width: -webkit-fill-available !important;
}

.language-dropDown {
  background: rgb(2, 22, 78) !important;
  border-radius: 4px !important;
  border: 0.5px solid rgb(255, 255, 255) !important;
  min-width: 13.5rem;
  height: 37px;

  /*jaya*/
  /* min-width: 11.5rem !important; */
  min-width: 13.5rem !important;
}

.tab-height {
  height: auto !important;
}

.jvectormap-tip {
  display: none !important;
}

.filter-vertical-align {
  top: -12px !important;
  z-index: 1000 !important;
  top: 20vh !important;
  right: 0 !important;
  display: grid !important;
  display: -ms-grid !important;
  position: fixed !important;
}

#dropdown1 {
  -ms-grid-row: 2;
}

#dropdown2 {
  -ms-grid-row: 3;
}

#dropdown3 {
  -ms-grid-row: 4;
}

#dropdown4 {
  -ms-grid-row: 5;
}

#dropdown5 {
  -ms-grid-row: 6;
}

#myInput {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}

#myInput1 {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}

#myInput2 {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}

#myInput3 {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}

#myInput4 {
  max-height: 5vh !important;
  width: 94% !important;
  border-radius: 30px !important;
  outline: none !important;
}

.arrow-clicks-right,
.arrow-clicks-left {
  top: 13px;
  position: absolute;
}

.arrow-clicks-right img,
.arrow-clicks-left img {
  width: 25px;
  height: 25px;
}

.arrow-clicks-right {
  right: 0;
}

.arrow-clicks-left {
  left: 0;
}

#intOpsTabs .MuiTabs-flexContainer {
  display: flex !important;
}

.MuiTabs-flexContainer {
  display: block !important;
}

.hm4-d-flex {
  display: flex;
  width: 98%;
  margin: 0 1%;
  overflow-x: auto;
}

.bottom-tab-active {
  margin: 6px !important;
  border-radius: 30px 30px 30px 30px !important;
  padding: 10px 10px 10px 10px !important;
}

.bottom-tab {
  margin: 6px !important;
}

.data-container {
  border-radius: 15px !important;
}

.bottom-tab:hover {
  border-radius: 30px 30px 30px 30px !important;
  padding: 10px 10px 10px 10px !important;
}

.MuiTabScrollButton-root {
  border-radius: 10px !important;
  flex-shrink: unset !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 1.5rem !important;
}

.clear-button {
  cursor: pointer;
}

.subTabWidth {
  max-width: "100vw" !important;
}

.popup-width {
  width: 75% !important;
}

.indicator-container {
  height: 100% !important;
}

.hidden {
  display: none;
}

.loading {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
}

.db-container {
  border-radius: 10px;
  height: 40rem;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  zoom: 0.75;
}

.db-container-maximized {
  border-radius: 10px;
  height: 127vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  zoom: 0.75;
}

.clientServed {
  position: relative;
  font-family: "GraphikMedium", Arial, sans-serif;
  margin-top: 0.4rem;
}

.hmcc-nav-text img {
  margin-bottom: 0px !important;
}

.tab-button-m {
  padding: 0px !important;
}

.btn-center {
  text-align: center !important;
}

.dash-title {
  font-size: 1.1rem !important;
  font-family: "GraphikMedium", Arial, sans-serif !important;
  padding-bottom: 8px;
}

button {
  outline: none !important;
}

/* For new subTab list */
.tab-pane .new-subTablist {
  padding: initial;
  margin: 0 0px 10px;
}

.tab-pane .new-subTablist #newSubTablist #appTabs {
  height: auto;
}

.tab-pane .new-subTablist .newTab-text {
  position: relative;
  margin: 0px 35px 0 0;
  opacity: 0.5;
  padding: 0.5rem;
  overflow: auto;
  line-height: 1.5;
  font-family: "GraphikSemibold", Arial, sans-serif;
  font-size: 16px;
}

.tab-pane .newTab-text:after {
  content: "";
  width: 0;
  height: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
}

.tooltiptext {
  visibility: hidden;
  width: 290px;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: 40%;
  font-size: smaller;
  margin-left: -60px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.newTab-text:hover+.tooltiptext {
  visibility: visible;
}

.overrideMe {
  max-width: 290px;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  display: block
}

.tab-pane .new-subTablist .newTab-text:hover::after,
.tab-pane .new-subTablist .newTab-text-active.newTab-text::after {
  left: 0;
  width: 100%;
}

.tab-pane .new-subTablist .newTab-text:hover,
.tab-pane .new-subTablist .newTab-text-active {
  opacity: 1;
}

/* For new main tab */
/* ravi */
.hmcc-nav .new-maintab-span {
  display: flex;
  align-items: center;
  padding: 0 0.9rem;
  margin: 0.6rem 0;
}

.profileIconHldr {
  /* padding: 0 .9rem; */
  margin: 0.6rem 0;
  cursor: pointer;
}

.profileIconHldr img {
  height: 0.7rem;
  margin-top: 0.7rem;
  margin-right: 1rem;
}

.profileCntnrDiv {
  position: fixed;
  right: 0px;
  top: 0px;
  /* width: 18%; */
  /*jaya*/
  width: 16.5rem;
  height: 100vh;
  z-index: 13;
  padding: 2rem 1.5rem 1.5rem;
}

.confidentialPopup {
  color: rgb(255, 255, 255);
  /* font-family: Graphik-Medium; */
  font-size: 16px;
  /* font-weight: 500; */
  height: 312px;
  letter-spacing: 0px;
  /* line-height: 24px; */
  width: 579px;
}

.confidentialCCPopup {
  color: rgb(255, 255, 255);
  font-family: "GraphikRegular";
  font-size: 16px;
  font-weight: 500;
  height: 312px;
  letter-spacing: 0px;
  line-height: 24px;
  width: 579px;
}

.header-modal {
  background: none !important;
}

.profileCntnrDiv .languageDropdown-options #myDropdownB9:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(23, 47, 112);
}

.announcementCntnrDiv {
  background: rgb(0, 24, 95);
  border-radius: 2px;
  height: 417px;
  width: 927px;
  position: absolute;
  left: 185px;
  top: 116px;
  z-index: 1060;
  padding: 0rem 0.9rem 0rem 0.9rem;
  box-shadow: 0px 0px 12px 0px rgba(153, 153, 153, 0.24);
  min-height: 64vh;
}
.tabFont .nav-item , .tabFont .nav-link.active{
  color: #ffffffd6 !important;
  font-size: 16px !important;
}

.announcementContent {
  max-height: 42vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 19.8rem;
  min-height: 42vh;
}

.announcementCntnrDiv .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffffd6 !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 0.8rem !important;
}

/* .announcementCntnrDiv .nav-tabs {
  border-bottom: 4px solid #dee2e6;
  width: 43%;
} */
.notificationlabel .nav-tabs{
  border-bottom: none;
}
.alertlabel .nav-tabs{
  border-bottom: none;
}

.announcementCntnrDiv .nav-link {
  padding: 1rem 0rem 0rem 0rem !important;
}

.announcementCntnrPopupDiv {
  max-height: 49vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.announcement-modal ::-webkit-scrollbar {
  width: 6px !important;
}

.notificationlabel {
  font-size: 16px;
  color: #ffffffd6;
}
.alertlabel {
  font-size: 12px;
  font-family: 'GraphikMedium' !important;
  color: #ffffffd6;
  /* position: relative; */
}
.dismiss{
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.dismissClosebtn{
    width: 10px;
    height: 10px;
    cursor: pointer;
    /* top: 0.5rem; */
    color: #f9fafb;
    position: absolute;
    left:96%;
}
.dismissAllCloseBtn{
  margin-top: 6.3px;
  margin-left: 9px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: #f9fafb;
}
.dismissAllBtn{
  background: #0c348d;
  cursor: pointer;
  padding-left: 4px;
  top: -3.6rem;
  border-radius: 17px;
  border: 1px solid #fff;
  height: 28px;
  width: 110px;
  position: absolute;
  right: 11rem;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding-top: 3.5px;
}
.downloadbtn .focus ,.downloadbtn:focus{
  box-shadow: none !important;
  outline: none;
}
.alertDownload{
  margin-right: 6px;
  margin-bottom: -2px;
}
.alertlabel .nav{
  height: 55px !important;
}
.titleChange{
  background-color: blue;
}
.downloadbtn {
    padding-left: 4px!important;
    background: #0c348d !important;
    top: -3.6rem;
    border-radius: 100px!important;
    border: 1px solid #fff!important;
    height: 28px;
    width: 110px;
    position: absolute;
    right: 2rem;
    color: #fff !important;
    display: flex!important;
    justify-content: center;
    font-size: 14px!important;
    padding-top: 3.5px!important;
}
.downloadbtn.focus ,.downloadbtn :focus{
  box-shadow: none;
}
.downloadbtn ::after {
  background: none;
  border-radius: none;
  border: none;
}
.arrowHeight{
  height: 11px;
}
.alerttable {
  color: white;
  background: rgb(8, 39, 133);
  border-radius: 2px;
  border: 1.7px solid rgb(255, 120, 73);
  height: 459px;
  width: 850px;
  max-width: 100%;
  margin-bottom: 1rem;
}
.alerttable .table thead {
  border-bottom: hidden;
}
.alerttable .table tbody tr {
  border-bottom: hidden;
}
.pagination-alert .react-bootstrap-table-pagination {
  /* float: right;
  margin-left: 21.3rem; */
  width: 100%;
}
.pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item
  .page-link {
  background-color: transparent;
  border-radius: 3px;
  color: white;
  border: none;
  box-shadow: none !important;
}
.pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-child(1),
  .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-child(2),
  .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-last-child(2){
    background-color:rgb(12, 52, 141) !important ;
    border: none !important;
  }
  .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination
  .page-item.active , .pagination-alert
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination .page-item:nth-last-child(1){
    /* border-radius: 3px;
    border: 1px solid rgb(220, 226, 231); */
    background-color:rgb(12, 52, 141) !important ;
    border: none !important;
  }
  .pagination-alert .react-bootstrap-table table td , .pagination-alert .react-bootstrap-table table th {
    white-space: nowrap;
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid rgb(0, 24, 95);
    cursor: pointer;
    color: white;
  }
  .pagination-alert .react-bootstrap-table table td {
    padding: 0.5rem 0.3rem 0.5rem 0.7rem !important;
    vertical-align: inherit;
    color: #fff;
  }

.confidentiallabel {
  font-size: 18px;
  color: #ffffffd6;
}

.announcementPopupFooter {
  position: absolute;
  bottom: 1rem;
  right: 0.8rem;
}
.announcementPopupFooterTab {
  bottom: 1rem;
  right: 0.8rem;
}
.announcementPopupFooterTab .form-group .checkboxAlignment .form-check-label{
 margin-top: 3px;
}

.announcementCntnrDiv.arrow-top {
  margin-top: 40px;
}

.announcementCntnrDiv.arrow-top:after {
  content: " ";
  position: absolute;
  right: 29px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid rgb(0, 24, 95);
}

.announcement-modal .announcementDescCntnr {
  width: auto;
}

.announcementDescCntnr {
  background: rgba(79, 95, 132, 0.42);
  border-radius: 4px;
  min-height: 4rem;
  width: 19rem;
  padding: 0.9rem;
}
.announcementDescCntnrMain {
  background: rgba(79, 95, 132, 0.42);
  border-radius: 4px;
  min-height: 4rem;
			   
  padding: 0.9rem;
}

.announcementDescCntnr:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

/* .announcementDescCntnrMain:hover {
  border: 1px solid rgb(1 142 255 / 76%);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
} */
.green-alert{
  background: green;
  border-radius: 13.5px;
  height: 22px;
  width: 50px;
  border-style: hidden;
  font-size: 0.7rem;
}

.yellow-alert{
  background: yellow;
  border-radius: 13.5px;
  height: 22px;
  width: 50px;
  border-style: hidden;
  font-size: 0.7rem;

}

.amber-alert{
  background: #FFBF00;
  border-radius: 13.5px;
  height: 22px;
  width: 50px;
  border-style: hidden;
  font-size: 0.7rem;

}

.red-alert{
  background: rgb(229, 109, 135);
  border-radius: 13.5px;
  height: 22px;
  width: 50px;
  border-style: hidden;
  font-size: 0.7rem;

}
 .react-bootstrap-table .table-hover tr:hover {
  background: rgba(79, 95, 132, 0.42);
  border-radius: 0px;
  border: 2px solid rgb(1, 142, 255);
  border-top: 2.1px solid rgb(1,142,255);
  border-bottom: 2px solid rgb(1,142,255);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 30%);
} 

.react-bootstrap-table .table-hover thead tr:hover{
  border: none;
  /* border-bottom: 2px solid rgb(1,142,255); */
}
.react-bootstrap-table .table-hover thead tr{
  font-size: 13px !important;
}
.announcementDesc {
  color: #ffffffd6;
  font-size: 14px;
  font-family: "GraphikRegular";
  word-break: break-word;
}

.announcementDate {
  color: #ffffff52;
  font-size: 11px;
  font-family: "GraphikRegular";
}

.announcementCntnrDiv .tab-content {
  padding-left: 0;
  padding-right: 0;
}

.announcementViewMore {
  color: rgb(1, 142, 255) !important;
  font-size: 14px;
  font-family: "GraphikMedium";
  cursor: pointer;
}

.notificationPopup {
  background: rgb(0, 24, 95);
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.24);
  height: 472px;
  width: 585px;
}

.profileCntnrDiv h6,
.profileCntnrDiv p {
  word-break: break-all;
  color: rgb(249, 250, 251);
  font-size: 14px;
}

.pagination-alert .react-bootstrap-table .table{
background: rgba(79, 95, 132, 0.42) !important;
border-radius: 0px ;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
width: 56rem;
}
.pagination-alert .react-bootstrap-table {
  font-family: GraphikMedium !important;
  min-height: 48vh;
}
.alertClose{
   position: absolute;
   right: 18px ;
   top :14px;
}

/*jaya*/
.profileCntnrDiv .userName {
  display: block;
  margin-top: 13px;
  margin-left: 12px;
}

/*jaya*/
.profileCntnrDiv .profile-heading {
  font-size: 20px;
  margin-top: -1px;
}

.profileCntnrDiv p {
  width: 7rem;
}

.profileLangaugeHldr {
  max-height: 35vh;
  overflow: auto;
}

.languageDropdown-options {
  background: rgb(2, 23, 78);
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  margin-left: 1rem;

  position: absolute;
  z-index: 1;
  overflow-x: auto;
  max-height: 29vh;
  /*jaya*/
  /* width: 11.5rem !important; */
  width: 13.5rem !important;
}

/*jaya*/
#myInput.languageSearch {
  border: 1px solid rgb(1, 142, 255) !important;
  background-position: 14px 7px !important;
  width: 87% !important;
  color: rgb(239, 242, 245);
  background-color: transparent !important;
  margin: 9px 13px 15px 13px;
}

/*jaya*/
.languageDropdown-options .dropdown-content#myDropdownB9 {
  width: auto !important;
  font-size: 0.8rem !important;
  height: 38px !important;
}

/*jaya*/
.profileCntnrDiv .hmcc-drop-btn {
  padding: 3px 15px;
}

input[type="checkbox"].switchTheme.DarkTheme:checked+div>div {
  background-color: #1dc7b3;
}

input[type="checkbox"].switchTheme.DarkTheme:checked+div {
  background-color: rgba(255, 255, 255, 0.15) !important
}

.userName:hover,
.userName {
  color: #fff !important;
}

.profileCntnrDiv p.small {
  font-size: 0.65rem;
}

.profileCntnrDiv .profileIcon {
  width: 1rem;
  vertical-align: text-top;
}

.profileCloseBtnAncr {
  cursor: pointer;
}

.announcementCloseBtnAncr {
  cursor: pointer;
}

.profileCloseBtn {
  width: 1rem;
  cursor: pointer;
  right: 0.9rem;
  /*jaya*/
  top: 1.2rem;
  /* top: 0.5rem; */
  position: absolute;
}

.announcementCloseBtn {
  width: 1rem;
  cursor: pointer;
  right: 0.9rem;
  /* top: .5rem; */
  position: absolute;
  color: #f9fafb;
}
.alertCloseBtn{
  width: 0.8rem;
  cursor: pointer;
  /* top: .5rem; */
  color: #f9fafb;
}
.notificationlabel .nav-tabs .nav-link {
  border: 1px solid transparent !important;
}
.alertlabel .nav-tabs .nav-link {
  border: 1px solid transparent !important;
}
.tabFont #uncontrolled-tab-example-tab-Alert.active{
  /* border-bottom: 4px solid rgb(12, 227, 187) !important; */
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
  border-bottom-color:  rgb(12, 227, 187) !important ;
  display: inline-block;
  margin: 0 0 -2px 0;
  padding: 30px 3px;
}
.tabFont #uncontrolled-tab-example-tab-Alert{
  width: 6rem;
font-family: "Graphik", sans-serif !important;
  /* font-family: GraphikMedium !important; */
}
.tabFont #uncontrolled-tab-example-tab-Announcement.active{
  border-bottom: 4px solid rgb(12, 227, 187) !important;
}
.tabFont #uncontrolled-tab-example-tab-Announcement{
  width: 8.5rem;
}
.react-bootstrap-table .table#tableBackground{
  background: rgba(79, 95, 132, 0.42);
  border-radius: 0px;
  margin-left: 1rem;
  color: #fff;
  /* border: rgb(0, 24, 95); */
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
}
.react-bootstrap-table .table-bordered{
  border: hidden !important;
}
.pagination-alert .page-item{
  margin-left: 9px !important;
}
.alertlabel .tabFont{
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color:  rgb(119, 135, 218);;
}
.profile-img {
  border-radius: 50%;
  /* height: 3.5rem;
  width: 3.5rem; */
  height: 3rem;
  width: 3rem;
}

.profileLangaugeHldr input[type="radio"] {
  position: relative;
  top: 2px;
}

.switchContainerTheme {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(-10%, -50%, 0);
}

.switchContainerTheme.disable {
  pointer-events: none;
  opacity: 0.7;
}

input[type="checkbox"].switchTheme {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switchTheme+div {
  vertical-align: middle;
  width: 2.7rem;
  height: 1.5rem;
  border-radius: 999px;
  background-color: #fff;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switchTheme+div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switchTheme+div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switchTheme+div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switchTheme:checked+div {
  width: 2.7rem;
  background-position: 0 0;
  background-color: #fff;
}

input[type="checkbox"].switchTheme+div {
  width: 2.7rem;
  height: 1.5rem;
  transform: scale(0.8);
  transform-origin: center;
}

input[type="checkbox"].switchTheme:checked+div {
  background-color: #fff;
}

input[type="checkbox"].switchTheme+div>div {
  float: left;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: inherit;
  background: rgb(1, 142, 255);
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 0px;
  margin-left: 1px;
  text-align: center;
}

input[type="checkbox"].switchTheme:checked+div>div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: rgb(1, 142, 255);
}

input[type="checkbox"].bigswitch.switchTheme+div>div {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 1px;
}

input[type="checkbox"].switchTheme:checked+div>div {
  -webkit-transform: translate3d(1.2rem, 0, 0);
  transform: translate3d(1.2rem, 0, 0);
}

input[type="checkbox"].switchTheme+div>div img {
  height: 0.6rem;
}

.hmcc-nav.new-maintab-div {
  padding-left: 1.8rem;
  align-items: initial;
}

.hmcc-client-name-hldr .new-maintab-span {
  position: relative;
}

.hmcc-logo-text::after {
  position: absolute;
  height: 1.5rem;
  width: 1px;
  content: "";
  top: 0px;
  margin: 0 1.4rem 0 1.4rem;
}

.synopsLogoCntnr {
  width: 40%;
}

.ClientNameLogoCntnr {
  width: 60%;
}

.hmcc-nav .new-maintab-span img {
  height: 18px !important;
  width: auto !important;
  margin: 0 10px 0 0 !important;
}

.hmcc-nav .new-maintab-span img.up-down-arw {
  height: 6px !important;
  margin: 0px 0px 0px 7px !important;
  transform: rotate(180deg);
}

.selectClientHolderDiv {
  border-radius: 1rem;
  min-height: 26rem;
  max-height: 80vh;
  width: 16rem;
  position: absolute;
  left: 6.5rem;
  top: 9vh;
  z-index: 10000;
  text-align: left;
  padding: 1rem 0px;
}

.ClientListInner {
  height: 61.7vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.selectClientHolderDiv a {
  cursor: pointer;
}

.selectClientHolderDiv h5 {
  padding-left: 1.5rem;
  font-size: 0.7rem;
  margin-bottom: 0rem;
  font-family: "GraphikRegular";
}

.clearSearchCTA {
  margin-right: 1rem;
  font-size: 0.85rem;
  position: relative;
  top: -8px;
}

.hmcc-client-logo-hldr {
  margin-left: 2.6rem;
  position: relative;
  top: 0px;
  height: 1.5rem;
}

.hmcc-nav .new-maintab-span .hmcc-client-logo-hldr img {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.hmcc-client-logo-hldr img {
  height: 1.2rem !important;
}

.selectClientHolderDiv img {
  height: 2rem;
}

.selectClientHolderDiv .media-body {
  font-size: 0.8rem;
  padding-top: 0.4rem;
}

/* uiteam ends */
.hmcc-nav .new-maintab-span img {
  height: 18px !important;
  width: 18px !important;
  margin: 0 10px 0 0 !important;
}

/* For IntOps button tabs(foundation) */
.intops-tab-button .new-intops-button-tabs {
  padding-left: 10px !important;
}

.intops-tab-button .new-intops-button-tabs img {
  margin-right: 1rem !important;
  height: 1.3rem;
}

.intops-tab-button .new-intops-button-tabs h6 {
  margin-bottom: 0;
  font-size: 1rem;
}

.tab-content {
  top: 0vh !important;
  position: relative !important;
  padding: 0px 1.8rem;
}

.anchor {
  font-size: medium;
  position: absolute;
  top: 31px;
  left: 85%;
  border: 2px solid;
  border-radius: 19px;
  padding: 0 7px 0 7px;
  cursor: pointer;
  z-index: 10;
}

.hidden {
  display: none !important;
}

.zero-padding {
  padding-bottom: 0;
  padding-top: 1rem;
  padding-right: 3rem;
}

/* App store */
.app-store-parent {
  padding-top: 8vh;
  padding-left: 10vw;
  padding-right: 10vw;
  overflow: auto;
}

.app-config-header {
  font-size: 17px;
  margin-left: -22px;
  margin-right: -22px;
}

.app-config-title {
  float: left;
}

.available-apps {
  padding-top: 7vh;
}

.app-store-modal {
  max-width: 90vw !important;
  width: 60vw !important;
  margin-top: 15vh !important;
  border-radius: 5px;
}

.app-store-modal-body {
  border-radius: 10px;
  height: 63vh;
}

.modal-desc {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.app-desc {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 2vh;
}

.app-para {
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 25px;
  padding-right: 3vw;
  padding-bottom: 4vh;
}

.appstore-modal-btn {
  padding: 5px 10px;
  width: 200px;
  border-radius: 30px;
  margin: 3px 5px;
  line-height: 25px;
  font-size: 14px;
  font-family: "GraphikBold", Arial, sans-serif;
  border: none;
  cursor: pointer;
}

.appstore-apply-btn {
  padding: 5px 10px;
  width: 100px;
  border-radius: 30px;
  margin: 3px 5px;
  line-height: 25px;
  font-size: 14px;
  font-family: "GraphikBold", Arial, sans-serif;
  border: none;
  float: right;
  cursor: pointer;
}

.app-container {
  margin-left: -30px;
}

.app-card {
  width: 245px;
  height: 150px;
  margin: 7px;
  border-radius: 6px;
}

.card-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.card-img {
  padding-left: 15px;
  padding-top: 20px;
}

.app-card-plus-btn {
  padding: 5px 10px;
  width: 70px;
  border-radius: 30px;
  margin: 3px 12px;
  line-height: 18px;
  font-size: 12px;
  font-family: "GraphikBold", Arial, sans-serif;
  cursor: pointer;
}

.app-card-minus-btn {
  padding: 5px 10px;
  width: 90px;
  border-radius: 30px;
  margin: 3px 12px;
  line-height: 18px;
  font-size: 12px;
  font-family: "GraphikBold", Arial, sans-serif;
  cursor: pointer;
}

.app-card-title {
  font-size: 18px;
  font-weight: 400px;
  padding-left: 15px;
}

.app-card-desc {
  padding-left: 15px;
  font-size: 14px;
}

.app-store-iframe {
  min-height: 111vh;
  height: inherit;
}

.app-store-iframe-maximized {
  min-height: 145vh;
  height: inherit;
}

.clientNameAdded .app-store-iframe {
  margin-top: 0vh;
}

.app-frame-container {
  height: 77vh;
  padding: 8px 12px;
  border-radius: 16px;
}

.clientNameAdded .app-frame-container {
  height: 70vh;
}

.dot-dot-icon {
  float: right;
  position: relative;
  top: 15px;
  right: -8px;
  cursor: pointer;
}

#appTabs img {
  width: 27px;
  height: 27px;
}

.OP-width-100 {
  width: 100%;
}

/* New App css by Snehal */
.appstore-container {
  padding: 1rem 9rem;
}

.appstore-container .appstore-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.app-action-btns .btn-cancel {
  border-radius: 1.25rem;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.0156rem;
  line-height: 0.875rem;
  padding: 0.563rem 1.438rem;
  cursor: pointer;
}

.app-action-btns .btn-apply {
  border-radius: 1.25rem;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.0156rem;
  line-height: 0.875rem;
  padding: 0.563rem 1.438rem;
  ;
  margin-left: 1rem;
  cursor: pointer;
}

.appstore-header .header-title {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.0344rem;
  line-height: 4rem;
  margin: 0;
}

.card-appstore-btns .btn-remove {
  border-radius: 1rem;
  background: transparent;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.016rempx;
  line-height: 0.75rem;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
}

.card-appstore-btns .btn-remove:hover {
  background: #1b5ef6;
}

.card-appstore-btns .minus-btn {
  margin-left: 0.313rem;
}

.card-appstore-icon img {
  height: 2rem;
  width: 2rem;
}

.appstore-currentApps h6,
.appstore-availableApps h6 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.03rem;
  line-height: 1.125rem;
  margin-bottom: 0.5rem;
}

.card-appstore-title {
  font-size: 0.938rem;
  word-break: break-word;
  font-weight: 500;
  letter-spacing: -0.017rem;
  line-height: 1.5rem;
  padding: 0.438rem 0rem;
  margin: 0.313rem 0rem;
}

.card-appstore-description {
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: -0.016rem;
  line-height: 0.938rem;
  margin-bottom: 1.125rem;
  min-height: 3.75rem;
  word-break: break-word;
}

.card-appstore {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem;
}

.appstore-card-container {
  width: 15.75rem;
}

.btn-add {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: -0.016rem;
  line-height: 0.75rem;
  background: transparent;
  border-radius: 1rem;
  padding: 0.5rem 1.1rem;
  cursor: pointer;
}

.card-appstore-btns .btn-add:hover {
  background: #1b5ef6;
  /* border: #1b5ef6; */
}

.appstore-availableApps {
  margin-top: 3.69rem;
}

.card-appstore-btns .plus-btn {
  margin-left: 0.625rem;
}

.appstore-container {
  padding: 1rem 9rem;
  overflow-y: auto;
  max-height: 90vh;
}

.Leftmodal .appstore-container {
  padding: 1rem 9rem;
  overflow-y: auto;
  max-height: 83vh;
}

.b9PopupTabContainer {
  flex: 0 0 96.666667%;
  max-width: 96.666667%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.b9PopupIconContainer {
  flex: 0 0 3.333333%;
  max-width: 3.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* New App css by snehal end */
.AS-no-apps {
  text-align: center;
}

.loader-size {
  width: 4rem;
  height: 4rem;
}

#foundation .new-subTablist .newTab-text {
  margin: 8px 35px 0 0;
  cursor: pointer;
}

#foundation .new-subTablist {
  margin: 0;
}

.inner-wo-scroll div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.separate-window {
  left: 28rem;
  position: relative;
  top: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.separate-windowB9 {
  left: 43rem;
  position: relative;
  top: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.business-height div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.powered-height div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.announcements-height div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.live-notifications-height div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.operations-height div {
  font-family: "Graphik", Arial, sans-serif !important;
}

.custom-ui {
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 30rem;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}

.react-confirm-alert-overlay {
  background: rgb(255 255 255 / 50%) !important;
}

.reactToolTipClient {
  opacity: 1 !important;
}

.hide-top-bar .text-center {
  opacity: 0;
}

.input-search-client {
  text-align: center;
}

#synopsLogo {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.hmcc-header-left {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 20%;
  margin-top: -3px;
}

.clientDropDown {
  display: flex;
  align-items: center;
}

.maximizedAppStore {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  border-radius: 0;
}

.maximizedIOJDashboard {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  border-radius: 0 !important;
  margin: 0 !important;
}

.app-store-iframe-maximizedB9 {
  min-height: 138vh;
  height: inherit;
}

.filtPane,
.filtPaneShow {
  position: absolute;
  width: 15px;
  height: 40px;
  top: 50%;
  border: 1px solid #bbb5b5;
  border-radius: 10px 0 0 10px;
  background-color: #bbb5b5;
  text-align: center;
  cursor: pointer;
  z-index: 12;
  opacity: 0.07;
  padding-top: 5px;
}   
.modal-content .filtPane,
.filtPaneShow {
  opacity: 1;
}
.modal-content .filterPanel{
  top:2.7%
}
.modal-content .filtContainer{
  height: 73vh;
}
.modal-content .iframe-modal-increasedheight .filtContainer{
  height: 84.3vh;
}
.filtPane {
  right: 0;
  color: white;
}

.filtPaneShow {
  right: 290px;
  color: white;
}

.filterPanelHide {
  visibility: hidden;
  position: absolute;
  display: none;
}

.filterPanel {
  position: absolute;
  width: 290px;
  z-index: 12;
  top: 20.7%;
  right: 0;
  background-color: #f5f5f5;
  text-align: center;
  color: black;
  font-weight: 500;
}

.filtContainer {
  background-color: #fff;
  text-align: left;
  font-size: small;
  height: 43vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: system-ui !important;
  font-weight: 500 !important;
  color: black !important;
  font-size: 13px !important;
}
.withoutButton.filterPanel{
  top: 9.7%;
}
.withoutButton.filterPanel .filtContainer{
  height: 47.8vh;
}
.withoutButton.filtPanMax1.filterPanel{
  top: 4.3%;
}
.withoutButton.filtPanMax1 .filtContainer{
height: 82vh;
}
.filtPanMax.filterPanel{
  top:11.4%
}
.filtPanMax.filterPanel .filtContainer{
  height: 76.5vh;
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.announcement-modal {
  width: 50vw !important;
  top: 9vh;
}

.announcement-modal .modal-content {
  background: rgb(0, 24, 95);
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.24);
  min-height: 66vh;
  width: 587px;
  right: 2rem !important;
}

.confidentialCCModal .modal-content {
  border-radius: 0.9rem;
  width: 616px;
  right: 50px;
}

.confidentialCCModal .modal-content .modal-body {
  padding: 1.5rem;

}

.announcement-modal-b9 {
  width: 25vw !important;
  left: -2vw;
}

.helpIcon {
  position: fixed;
  cursor: pointer;
  bottom: 1.2rem;
  right: 2rem;
  height: 60px;
  width: 60px;
}

.feedback-modal-header {
  height: 56px;
  background: linear-gradient(-225deg,
      rgb(6, 66, 147) 0%,
      rgb(4, 46, 115) 50%,
      rgb(14, 43, 102) 100%);
  border-radius: 16px 16px 0px 0px !important;
}

.feedback-modal-header-submit {
  height: 56px;
  background: linear-gradient(-225deg,
      rgb(6, 66, 147) 0%,
      rgb(4, 46, 115) 50%,
      rgb(14, 43, 102) 100%);
}

.feedbackSubmitted-modal .feedback-modal .modal-content {
  margin-top: 9rem;
}

.feedback-submit {
  margin-top: 1.3rem;
  position: absolute;
}

.feedbackSubmittext {
  color: grey;
  font-weight: bold;
  margin-left: 2.5rem;
}

.newFeedback {
  color: grey;
  font-size: small;
  margin-top: 1rem;
}
.uploaded_file{
  float: right; 
  font-size: 0.8rem;
  padding: 1px 12px;
  text-align: right;
}

.newFeedbackSubmitted {
  text-decoration: underline;
  color: rgb(0, 123, 255);
  cursor: pointer;
}

.feedbackSubmitted-modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.submitFeedbackFocus .focus, .submitFeedbackFocus:focus {
  box-shadow: none;
}

.feedback-footer .btn-primary.focus, .feedback-footer .btn-primary:focus {
  box-shadow: none;
}

.feedback-modal .modal-content {
  border-radius: 1.1rem !important;
  border: none !important;
}

.feedback-modal-title {
  /* width: 299px; */
  /* height: 16px; */
  color: #ffffffdb !important;
  font-size: 15px !important;
  font-family: "GraphikRegular" !important;
  display: flex;
  /* font-weight: normal;
  letter-spacing: -0.33px; */
}

.feed-button {
  width: 217px;
  height: 84px;
  background: rgb(255, 255, 255);
  /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15);
  border-radius: 8px;
}

.feedback-footer {
  height: 72px;
  background: rgb(244, 246, 248);
  border-radius: 0px 0px 16px 16px;
  border-top: none !important;
}

.footer-btn {
  width: 95px;
  height: 40px;
  border-radius: 20px !important;
}

.feedback-label {
  width: 361px;
  height: 22px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: GraphikMedium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 22px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.feedback-select {
  width: 280px !important;
  margin-left: 16px;
}

.feedback-general {
  width: 217px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}

.feedback-newidea {
  width: 373px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15); */
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}

.feedback-productInfo {
  width: 333px;
  height: 84px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.15); */
  border-radius: 8px;
  margin-bottom: 64px;
  margin-left: 32px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
}

.feedbackNewIdea {
  height: 1.3rem;
  margin-top: 0.9rem;
}

.feedback-type-label {
  /* height: 22px; */
  color: rgb(99, 115, 129);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: -7%;
}

.feedback-type-labelSelect {
  /* height: 22px; */
  color: rgb(0, 46, 153);
  font-size: 1rem;
  font-family: GraphikMedium;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: -7%;
}

.feedbackLabel {
  /* height: 22px; */
  color: rgb(99, 115, 129);
  font-size: 1rem;
  font-family: GraphikMedium !important;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}

.feedbackLabelChange {
  color: rgb(0, 46, 153);
  font-size: 1rem;
  font-family: GraphikMedium !important;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}

.feedbackLabelSelect {
  /* height: 22px; */
  color: rgb(0, 46, 153);
  font-size: 1rem;
  font-family: GraphikMedium !important;
  /* font-weight: 500; */
  text-align: center;
  /* letter-spacing: -0.35px;
  line-height: 22px; */
  position: relative;
  top: 4%;
}

.feedbackSubmitDiv {
  background-color: #fff;
  width: 30% !important;
  margin: 15% auto auto;
  border-radius: 16px;
  padding: "20px";
  overflow-x: hidden;
  overflow-y: auto;
}


.type-icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 15%;
}

.selected-type {
  width: 217px;
  height: 4px;
  background: rgb(0, 46, 153);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  bottom: -4%;
}

.selectedFeedbackType {
  /* width: 217px; */
  height: 4px;
  background: rgb(0, 46, 153);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  bottom: -16%;
}
.fileUploadMessage {
  color: red;
  background-color: antiquewhite;
  margin-left: 39.15rem;
  font-size: 0.8rem;
}
.attachmentPosition{
  margin-left: 39.75rem;
  color: rgb(0, 46, 153);
  cursor: pointer;
  margin-bottom: 0rem !important;
  display: inline;
}
.feedbackradioButtons .form-check-label{
  font-size: 1rem !important;
}
.feedbackradioButtons .form-check-input{
  margin-top: 7px;
}
.plusButton{
  color: rgb(0, 46, 153);
  margin-right: 3px;
}
.fileColor{
  color: gray;
}
.fileErrorMessage{
  color: red;
  background-color:antiquewhite ;
  margin-left: 34.5rem;
  font-size: 0.8rem;
}
.morefilesErrorMessage{
  color: red;
  background-color:antiquewhite ;
  margin-left: 35rem;
  font-size: 0.8rem;
}
.feedbackradioButtons{
  width: auto;
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  margin-left: 1rem !important;
}
.crossIcon{
    font-size: 0.9rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.fileSizeMessage{
  color: red;
  background-color: antiquewhite;
  margin-left: 27.9rem;
  font-size: 0.8rem;
}
.attachmentFont{
  font-style: italic;
  margin-left: 30.5rem;
  font-size: 0.8rem;
}
.filetypeFont{
  font-size: 0.8rem;
  margin-left: 38.5rem;
}

.wrapper {
  width: auto;
  height: auto;
}

.circle {
  width: 40px;
  height: 40px;
  background: dodgerblue;
  border-radius: 100px;
  position: relative;
  margin: 0px auto 0 auto;
}

.total-issues {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.vertical-line {
  width: 2px;
  height: 32px;
  background: lightgray;
  margin: 0px auto 0 auto;
}

.feedback-footer-general {
  height: 72px;
  background: white;
  border-radius: 0px 0px 16px 16px;
  border-top: none !important;
}

.feedback-modal .close {
  color: #fff;
  position: relative;
}

.feedback-modal .close:hover {
  color: #fff;
  position: relative;
}

.feedback-modal .close span, .feedback-modal .close:hover span {
  position: absolute;
  right: 15px;
  top: 11px;
}

.business_modal-holder .modal-lg {
  max-width: 53rem !important;
}

.userProfile {
  min-height: 19.17px;
  width: 19.17px;
}

.bellIcon {
  min-height: 19.17px;
  width: 19.17px;
}

.bellIconHldr {
  cursor: pointer;
}

.bellIconHldr img {
  height: 0.7rem;
  margin-top: 0.7rem;
  margin-right: 1rem;
}

.formCheck.form-group {
  margin-bottom: 0rem !important;
}

.checkboxAlignment .form-check-input,
.checkboxAlignment {
  margin-top: 0.5rem !important;
}

hr.versionAndTermsOfUse {
  border-top: 1px solid white;
  margin-left: -1.2rem !important;
  margin-bottom: 0.5rem !important;
}

.fontChange {
  font-weight: 200;
  line-height: 1.5;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
}

.termsOfUse {
  cursor: pointer;
  left: 8.5rem;
  position: absolute !important;
}

.hmcc-nav .new-maintab-span img.artboardIcon {
  height: 16px !important;
  width: 16px !important;
  margin-top: 3px !important;
}

.sisense-css-module indicator.widget-body,
.text-block.horizontal-center {
  cursor: pointer !important;
}

.sisense-css-module indicator.widget-body,
.text-block.horizontal-center.RemoveCursor {
  cursor: default !important;
}

.background-blur {
  background: rgba(0, 0, 0, 0.7);
}

.background-blur-Confidential {
  background: rgba(0, 0, 0, 0.8);
}


.headerMenuContainer {
  flex: 0 0 70%;
  max-width: 70%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 0px;
}

.align-right {
  -webkit-justify-content: flex-end !important;
  justify-content: flex-end !important;
}

.disp-flex {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
}

.compDashboard {
  height: 80vh;
  overflow-y: auto;
  margin-top: 20px !important;
  overflow-x: hidden;
}

.expBtn {
  position: absolute;
  top: -9%;
  right: 3%;
  cursor: pointer;
}

.title {
  padding: "5px 20px";
  color: white;
  background-image: linear-gradient(rgb(34, 145, 255), rgb(3, 48, 198));
  margin: 0 5px;
  height: 29px;
  padding: 5px;
}

.indicators {
  height: 135px;
}

.openInc {
  height: 336px;
}

.indicators,
.openInc {
  box-sizing: border-box;
}

.indicators1,
.openInc1 {
  box-sizing: border-box;
  width: 48%;
}

.MuiGrid-container {
  flex-wrap: nowrap !important;
}

.indicatorMain .MuiGrid-grid-xs-true {
  width: 18% !important;
}

.logoutIcon {
  /*jaya*/
  /* margin-left: 7rem;
  margin-top: 11rem;
  border-radius: 15px;
  border: 1px solid rgb(151, 151, 151); */

  /* margin-left: 7rem;
  margin-top: 100px; */
  border-radius: 2rem;
  padding: 2px 11px 2px 12px;
  border: 1px solid rgb(151, 151, 151);
  cursor: pointer;
  font-size: 15px;
  color: rgb(255, 255, 255);
  position: absolute;
  right: 21px;
  bottom: 44px;
}

.new-tab-message {
  left: 39rem;
  position: relative;
  top: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.hide-overflow {
  overflow: hidden !important;
}

/*Filter OK button*/
.uc-ok {
  background-color: #a100ff !important;
}

.filtPaneCss,
.filtPaneShowCss {
  position: absolute;
  width: 20px;
  height: 30px;
  top: 28vh;
  border: 1px solid black;
  border-radius: 0 10px 10px 0;
  background-color: black;
  text-align: center;
  cursor: pointer;
  z-index: 10;
}

.filtPaneCss {
  left: 5%;
  color: white;
}

.filtPaneShowCss {
  left: 44vw;
  color: white;
}

.filterPanelCss {
  position: absolute;
  width: 290px;
  z-index: 12;
  top: 28vh;
  left: 5%;
  background-color: #f5f5f5;
  text-align: center;
  color: black;
  font-weight: 500;
}

.css-filter-buttons {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.css-filter-buttons .MuiSelect-root {
  color: white;
}

.css-filter-buttons .MuiSvgIcon-root {
  color: white !important;
}

.MuiMenu-list {
  background: #0058ff !important;
  color: white;
}

.css-filter-buttons .MuiInput-underline:before {
  content: none;
}

.power-bi-frame {
  min-height: 109vh;
  width: 100% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(1) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(1) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(1) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(1) !important;
  -webkit-transform-origin: 0 0 !important;
}

.power-bi-frame-maximized iframe {
 height: 145vh !important;
}
.power-bi-frame-maximized {
  min-height: 109vh;
  width: 100% !important;
  overflow: hidden;
  height: inherit !important;
  zoom: 1 !important;
  transform: scale(1) !important;
  transform-origin: 0 0 !important;
  -moz-transform: scale(1) !important;
  -moz-transform-origin: 0 0 !important;
  -o-transform: scale(1) !important;
  -o-transform-origin: 0 0 !important;
  -webkit-transform: scale(1) !important;
  -webkit-transform-origin: 0 0 !important;
}

* {
  font-family: "GraphikMedium" !important;
}

.db-container-powerbi {
  border-radius: 10px;
  height: 105vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 0.75;
}

.db-container-powerbi-maximized {
  border-radius: 10px;
  height: 123vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 0.75;
}

.power-iframe iframe {
  height: 154% !important;
}

#textareaHTML {
  background: transparent;
  resize: none;
  border: 0 none;
  width: 100%;
  outline: none;
  height: inherit;
  overflow: hidden;
  /* min-height: inherit; */
}

.maximizedIOJPopup {
  position: fixed !important;
  top: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
  transform: none !important;
  max-width: none !important;
  /* background: #0058ff; */
  display: block !important;
}

.iframe-modal-increasedheight {
  height: 100vh;
}

.maximizeIconIOJPopup {
  position: absolute;
  right: 50px;
  top: 6px;
}

.maximizeIcondbContainer {
  position: absolute;
  right: 9px;
  top: 6px;
}

.maximizedIOJPopup .modal-content {
  background: #0058ff;
  border-radius: 0;
}

.dashboard-modal-header .close {
  padding: 1rem 1rem 1rem 0 !important;
}

.maxminIconAlignment {
  margin-top: -8px;
  margin-right: -6px;
}

#intOpsTabs .tab-pane .new-subTablist .newTab-text {
  overflow: initial !important;
}

#intOpsTabs {
  width: 100%;
}

#intOpsScrollBtns {
  width: 99.5%;
}


#intOpsScrollBtns .MuiTabScrollButton-root {
  width: 20px !important;
}

#intOpsScrollBtns .hmcc-drop-btn {
  margin-top: 5px !important;
}
.ioj-filter-buttons{
  position: absolute;
    right: 80px;
    top: 6px;
}
.alert-count-button{
  background: rgb(28, 66, 171);
  border-radius: 13.5px;
  border: 1px solid rgb(205, 112, 124);
  height: 18px;
  width: 39px;
  color: white;
  font-size: 12px;
}
.bell-count{
  cursor: pointer;
  background: rgb(213, 0, 47);
  top: -0.4rem;
  height: 21px;
  width: 21px;
  border-radius: 1rem;
  letter-spacing: -0.34px;
  text-align: center;
  font-family: Graphik-Medium;
  font-size: 8px;
  font-weight: 500;
  position: relative;
  left: -2.3vw;
  color: white;
  border-style: hidden;
}
/* Ticket SLA dashboard */			
.sisense-css-module .tablewidget-widget-content #grid_paginate .paginate_button, .sisense-css-module .tablewidget-widget-content .dataTables_wrapper .dataTables_paginate .paginate_button{
  display: inline-table !important;
  line-height: 1px !important;
}		

.react-bootstrap-table-page-btns-ul{
  float: right;
}
.react-bootstrap-table-pagination-list{
padding-right: 0px;
}
/* .highcharts-data-label {
  display: block !important;
} */
/* SVM dashboard */
.data-visualisation-container {
  background: #00166b;
  border-radius: 5px;
  padding: 0.5rem;
}	
.data-visualisation-container .title-section{
  zoom:90%
}
.data-visualisation-container .title-container .title{
height: auto;
}
.data-visualisation-container .graph-view-section {
  margin-top: -2vh;
  zoom: 80%;
}
.data-visualisation-container #wheel-chart-box{
margin-top: -12vh;
}
.data-visualisation-container img{			
vertical-align: middle;
}
.title-container .title{
  background: transparent;
}
#right-section .tspanLabel{
  display: none;
}
.maximizedSVMDashboard {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  border-radius: 0 !important;
  margin: 0 !important;
}		   
.maximizedSVMDiv{
  max-height: 95vh;
  overflow: auto;
}
.minimizedSVMDiv{
  max-height: 74vh;
  overflow: auto;
}
.svmMaxMinIcon{
  position: relative;
  top:0.5vh;
  right: 1vw;
}
/* Zoom issue fix */
/* .iframe-modal-body #sisenseModal.db-container{
  zoom: 1;
  height: 31rem;
} */
.iframe-modal-increasedheight #sisenseModal.db-container{
  zoom: 1;
  height: 34rem;
}
.data-container2.tab4-background{
  padding-top: 5px !important;
}
div.hidden {
  display: none;
}
.tabberAct{
color: #212b36;
font-size: 20px;
font-weight: 700;
-webkit-text-decoration: underline 5px #a100ff;
text-decoration: underline 5px #a100ff;
text-underline-offset: 0.5em;
background: #faf9fb;
}
/* banking dashboard */
.sisense-css-module.sis-scope.sisense-widget-wrapper.parentCSS{
background-color: transparent !important;
height: 48px !important;
}
.listDefaultCSS .listItemDefaultCSS{
  color: white;
}
.data-visualisation-container .MuiTabScrollButton-root{
  background-color: #fff !important;
}
.bodytext18-medium-midnight, .bodytext18-medium-white {
  font-family: Graphik-Medium !important;
}				
.recharts-surface{
transform-box: fill-box;
transform-origin: 50% 28.25%;
}
/* SVM Dark Theme */

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-title .title-section h4, 
.dark_theme_container .svm-dark-theme  .data-visualisation-container .title-section h4,
.dark_theme_container .svm-dark-theme  .data-visualisation-container .bodyText24-Semibold,
.dark_theme_container .svm-dark-theme  .visualization-collapse .card-header button .bodytext18-medium-midnight,
.dark_theme_container .svm-dark-theme .data-visualisation-container #prog-view,
.dark_theme_container .svm-dark-theme  .data-visualisation-container .projectDetails-modal-header h4,
.dark_theme #dashboard-tooltip-right .dashboard-details-wrap h6,
.dark_theme #clientDetails-popover .detail-popup-section .section-title,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content .searchable-dropdown-input::placeholder,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown .display-list svg{
  color: white !important;
}
.dark_theme_container .svm-dark-theme  .data-visualisation-container .project-tabs-list .body-small{
  color: #000 !important;
}

.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-1pkbwyu-control,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-1sgei2z-control,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-1pkbwyu-control,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-1sgei2z-control,
.dark_theme_container .svm-dark-theme .data-visualisation-container .btn-outline-stat-alternate{
  background: transparent;
} 

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor {
    background: transparent !important;
}

.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu,
.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu div,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu>div{
  background: #02174E;
  color: white;
}

.dark_theme_container .svm-dark-theme .reportingYrDropdown .multi-select-dropdown .css-192gmcv-menu>div div:hover,
.dark_theme_container .svm-dark-theme .statusDropdown .multi-select-dropdown .css-192gmcv-menu>div div:hover{
  background: #172f70;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-box{
  background: rgb(21, 4, 97);
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-details ul li div:first-child,
.dark_theme_container .svm-dark-theme .data-visualisation-container .dm-card-details ul li p,
.dark_theme_container .svm-dark-theme .data-visualisation-container #prog-view-colored,
.dark_theme_container .svm-dark-theme .data-visualisation-container .title-container .fy,
.dark_theme_container .svm-dark-theme .data-visualisation-container .title-container .title,
.dark_theme_container .svm-dark-theme .data-visualisation-container .graph-right .trend-header,
.dark_theme_container .svm-dark-theme .data-visualisation-container .projectDetails-modal-header h5,
.dark_theme #dashboard-tooltip-right .dashboard-details-wrap p,
.dark_theme #clientDetails-popover .close-btn,
.dark_theme #programDetails-popover .close-btn,
.dark_theme #clientDetails-popover .popover-body,
.dark_theme #programDetails-popover .popover-body{
  color: white !important;
 }

 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div#selectmenu-anchor .display-field.field-label,
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown svg,
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-detail-tabs button .subtabs-UOM{
   color: white !important;
 }
 .dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .body-regular{
  color: #000 !important;
  line-height: 13px;
 }

 .dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-wheel-spacing,
.dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-wheel-vd-spacing{
    fill: transparent;
  }
  
.dark_theme_container .svm-dark-theme .recharts-layer.recharts-pie-sector path{
  stroke: transparent;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options.display-list,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options .searchable-dropdown-content,
.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options div.dropdown-list{
  background: #1636a8;
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .project-tabs-list .groove-select-dropdown div.selectmenu-options.display-list .dropdown-item:hover{
  background: #3d36fd !important;
  color: white !important;
}

.dark_theme_container .svm-dark-theme  .data-visualisation-container #legend-container{
  color: rgb(181, 192, 202);
}

.dark_theme_container .svm-dark-theme .visualization-collapse.accordion .card,
.dark_theme_container .svm-dark-theme .visualization-collapse.accordion .card .card-header{
  background: rgb(19, 39, 190);
}

.dark_theme_container .svm-dark-theme .data-visualisation-container .dashboard-projectDetails-modal{
  background: rgb(33, 48, 253);
}

.dark_theme #client-stage-defination.bs-tooltip-bottom .tooltip-inner,
.dark_theme #dashboard-tooltip-right .tooltip-inner,
.dark_theme #clientDetails-popover,
.dark_theme #programDetails-popover{  
  background: #322f8e !important;
}

.dark_theme #client-stage-defination.bs-tooltip-bottom .arrow::before,
.dark_theme #dashboard-tooltip-right.bs-tooltip-bottom .arrow:before{
  border-bottom-color: #37404a !important;
}

.dark_theme #clientDetails-popover.bs-popover-right > .arrow::after{
  border-right-color: #30189c !important;
}

/* .dark_theme_container .svm-dark-theme #wheel-chart-container .recharts-surface svg{
  filter: brightness(0) invert(1);
} */

.dark_theme_container .svm-dark-theme #wheel-chart-container .recharts-surface .recharts-layer svg{
  filter: none;
}

.data-visualisation-container .projectDetails-modal-header .fa-times{
  cursor: pointer;
}
.data-visualisation-container .client-details-button{
  color: white;
}
.data-visualisation-container .client-stage-label{
  color: white;
}
.data-visualisation-container .multi-select-dropdown{
  border: 1.5px solid white;
  color:white
}
.data-visualisation-container .multi-select-dropdown div[class*=-singleValue] {
  color: #ffffff;
}
.data-visualisation-container .css-1affx0b-indicatorContainer{
  color: white;
}
.data-visualisation-container .client-stage-details>span {
  color: white !important;
}
.data-visualisation-container .css-192gmcv-menu{
  border: 0.5px solid rgb(255, 255, 255);
  width: 100% !important;
}
.data-visualisation-container .css-6tu5kw-option{
  color:white;
  background-color: transparent;
}
.data-visualisation-container .css-17a4bv1-option{
  color:white;
  background-color: transparent;
}
.data-visualisation-container .css-1ubxw6c-option{
  color:white;
  background-color: transparent;
}
.data-visualisation-container .css-85il6e-option{
  color:white;
  background-color: transparent;
}
.data-visualisation-container .project-detail-tabs button span{
  color: white;
}
.data-visualisation-container .css-zug4vy-option {
  background-color: #172f70;
}
.data-visualisation-container .css-1mw8vlk-option {
  background-color: #172f70;
}
.data-visualisation-container .css-1v6tlj4-option {
  background-color: #172f70;
  color: white;
}
.data-visualisation-container .css-2pizvi-option {
  background-color: #172f70;
  color: white;
}
.data-visualisation-container .css-p5k3u5-indicatorContainer {
color:white;
}
.data-visualisation-container .comment-section .comment{
  color: white;
}
.svm-db-barchart-tooltip{
z-index: 5000;
}
.css-1pkbwyu-control{
  line-height: 18px !important;
}
.css-1sgei2z-control{
  line-height: 18px !important;
}
.data-visualisation-container .accordion-button-dashboard{
  color: white !important;
}
.svg-inline--fa.fa-chevron-down.fa-w-14.accordion-button{
  color: white !important;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.dark_theme_container .svm-dark-theme .data-visualisation-container .program-box-container{
  margin-left: 2%;
}
/* SVM Dark Theme */
.access-denied{
  font-family: "GraphikMedium" !important;
  text-align: center;
}
.requestSuccess .modal-content{
  top:22vh;
}
.getAccess{
  color: #4169e1;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: #00f;
  text-decoration-color: #00f;
}
			   
			  
			   
				  
 
			
							  
			   
			  
						
						  
					 
							  
				  
				   
					 
			 
			   
					   
 